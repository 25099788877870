import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';

import HeaderImage from '../../content/assets/header.jpg';
import TwitterLogo from '../../content/assets/logos/twitter.png';
import typography, { rhythm } from '../utils/typography';
import TechStack from './TechStack';
import styled from '@emotion/styled';

export type LayoutProps = {
  location: Location;
  title?: string;
};

const MainContainer = styled.main`
  @media (max-width: 768px) {
    width: 100%;
  }

  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 1600px;
  padding: ${rhythm(3 / 4)};
`;

const Layout: FunctionComponent<LayoutProps> = (props) => {
  const { location, children } = props;
  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  if (location.pathname === rootPath || location.pathname.includes('tags/')) {
    header = (
      <div
        style={{
          backgroundImage: `url(${HeaderImage})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          display: 'table',
          height: '30vh',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            width: '100%',
            backgroundColor: '#0000001F',
            textAlign: 'center',
          }}
        >
          <h1
            style={{
              color: '#FFF',
              margin: '60px 0 15px 0',
              fontSize: '2.5em',
              fontWeight: 'bold',
              textShadow: '1px 1px 5px #777',
            }}
          >
            <a
              href="/"
              title="Accueil"
              style={{
                color: 'inherit',
                textDecoration: 'none',
                textShadow: '1px 1px 5px #777',
              }}
            >
              @VinceOPS
            </a>
          </h1>
          <h2 style={{ color: '#FFF', margin: 0, fontSize: '1.2em' }}>Blog d'un passionné</h2>
          <TechStack />
        </div>
      </div>
    );
  } else {
    header = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(36),
          height: 80,
          padding: 20,
        }}
      >
        <Link
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            fontWeight: 'bold',
            fontSize: '1.4em',
            fontFamily: typography.options.headerFontFamily![0],
          }}
          to="/"
          title="Retour au blog"
        >
          @VinceOPS
        </Link>
        <a href="https://twitter.com/VinceOPS" title="@VinceOPS sur Twitter" style={{ height: 40 }}>
          <img src={TwitterLogo} style={{ marginBottom: 0 }} alt="Mon Twitter" />
        </a>
      </div>
    );
  }
  return (
    <>
      <header>{header}</header>
      <MainContainer>{children}</MainContainer>
      <footer style={{ textAlign: 'center' }}>
        © {new Date().getFullYear()}, conçu avec ❤️ et{' '}
        <a href="https://www.gatsbyjs.org" title="Gatsby">
          {' '}
          Gatsby
        </a>{' '}
        &bull;{' '}
        <a href="/rss.xml" title="Flux RSS">
          RSS
        </a>
      </footer>
    </>
  );
};

export default Layout;
