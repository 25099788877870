import React, { FunctionComponent, Children } from 'react';

import TsLogo from '../../content/assets/logos/typescript.png';
import NestLogo from '../../content/assets/logos/nestjs.png';
import NodeJsLogo from '../../content/assets/logos/nodejs.png';
import ReactLogo from '../../content/assets/logos/reactjs.png';
import RustLogo from '../../content/assets/logos/rustacean.png';
import WasmLogo from '../../content/assets/logos/wa.png';

type TechStackProps = {
  iconSize?: number;
};

const getIcons = (iconSize: number) => [
  <img alt="TypeScript" title="TypeScript" src={TsLogo} width={iconSize} key="ts" />,
  <img alt="Node.js" title="Node.js" src={NodeJsLogo} width={iconSize} key="node" />,
  <img alt="Nest" title="Nest" src={NestLogo} width={iconSize} key="nest" />,
  <img alt="React" title="React" src={ReactLogo} width={iconSize} key="react" />,
  <img alt="Rust" title="Rust" src={RustLogo} width={iconSize} key="rust" />,
  <img alt="WebAssembly" title="WebAssembly" src={WasmLogo} width={iconSize} key="wasm" />,
];

const TechStack: FunctionComponent<TechStackProps> = ({ iconSize = 32 }) => {
  const icons = getIcons(iconSize);

  return (
    <>
      <span
        style={{
          display: 'flex',
          alignItems: 'baseline',
          width: iconSize * icons.length + icons.length * 12, // 12px between each icon (thanks to "space-between")
          justifyContent: 'space-between',
          margin: '20px auto',
          padding: 10,
          height: iconSize + 20, // icon size + padding top + padding bottom
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius: 7,
        }}
      >
        {icons}
      </span>
    </>
  );
};

export default TechStack;
