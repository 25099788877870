import React, { FunctionComponent } from 'react';
import Helmet, { HelmetProps } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        image
      }
    }
  }
`;

export type SeoProps = {
  description?: string;
  lang?: string;
  meta?: HelmetProps['meta'];
  keywords?: string[];
  title?: string;
  ogImage?: string;
};

const Seo: FunctionComponent<SeoProps> = (props) => {
  const { description = '', lang = 'en', meta = [], keywords = [], title = '', ogImage } = props;

  const data = useStaticQuery(detailsQuery);
  const metaDescription = description || data.site.siteMetadata.description;
  const ogImageUrl = `${data.site.siteMetadata.siteUrl}${ogImage || data.site.siteMetadata.image}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${data.site.siteMetadata.title} | %s`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:site_name',
          content: data.site.siteMetadata.title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: data.site.siteMetadata.siteUrl,
        },
        {
          property: 'og:image',
          content: ogImageUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: data.site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:site',
          content: data.site.siteMetadata.title,
        },
        {
          name: 'twitter:image',
          content: ogImageUrl,
        },
        {
          name: 'image',
          content: ogImageUrl,
        },
        ...meta,
      ].concat(
        keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
          : []
      )}
    >
      <link rel="me" href="https://twitter.com/VinceOPS" />
    </Helmet>
  );
};

export default Seo;
