import Typography from 'typography';
import GrandView from 'typography-theme-grand-view';

GrandView.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },
  a: {
    backgroundImage: 'none',
    textShadow: 'none',
  },
});

const typography = new Typography(GrandView);

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles();
}

export default typography;
export const { rhythm } = typography;
export const { scale } = typography;
